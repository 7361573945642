import React from 'react'

const Footer = () => {
    return (
        <footer>
            <a style={{textDecoration: 'none'}} href='https://blacklivesmatter.com/black-women-are-divine/' target='_blank' ><h3 style={{margin: 0}}>Black Lives Matter</h3></a>
            
        </footer>
    )
}
export default Footer;

